import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from '.';

import { loadState, saveState } from './sessionStorage';

const persistedState = loadState();
const store = createStore(
	rootReducer,
	persistedState
);

store.subscribe(() => {
	saveState(store.getState());
});

export default ({ element }) => (
	<Provider store={store}>{element}</Provider>
);