const initialState = {
    language: null,
    current: null,
    openMenu: false,
};

const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const changeLang = value => ({
    type: CHANGE_LANGUAGE,
    value,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return { ...state, language: action.value };
        default:
            return state;
    }
};
