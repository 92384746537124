exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-vacancies-js": () => import("./../../../src/components/templates/vacancies.js" /* webpackChunkName: "component---src-components-templates-vacancies-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-page-js": () => import("./../../../src/pages/blogPage.js" /* webpackChunkName: "component---src-pages-blog-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-pangaia-js": () => import("./../../../src/pages/projects/pangaia.js" /* webpackChunkName: "component---src-pages-projects-pangaia-js" */),
  "component---src-pages-projects-spot-js": () => import("./../../../src/pages/projects/spot.js" /* webpackChunkName: "component---src-pages-projects-spot-js" */),
  "component---src-pages-projects-tom-js": () => import("./../../../src/pages/projects/tom.js" /* webpackChunkName: "component---src-pages-projects-tom-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */)
}

